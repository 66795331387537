import React from 'react'
import Wrapper from '../../components/wrapper'
import AlertComponent from '../../components/pageComponents/alerts'
const Alerts = ({ location }) => {
  return (
    <Wrapper location={location} title="Alerts | MedReps.com">
      <AlertComponent />
    </Wrapper>
  )
}
export default Alerts
