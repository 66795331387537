import React, { useState, useEffect, useSearchParams } from 'react'
import { Dropdown, Select, Tag, Table, message, Menu } from 'antd'
import { displayValueHandler, serialize } from '../../../functions'
import { navigate, Link } from 'gatsby'
import cookies from 'react-cookies'
import axios from 'axios'
import { strapiURL } from '../../../config'
import { emailFrequencyJobAlert } from '../../../data'
import EditSearchModal from '../../Modals/candidateSearchEditModal'
import { useSelector } from 'react-redux'
const Alerts = ({ userProfile }) => {
  const [loading, setLoading] = useState(true)
  const [editSearchModal, setEditSearchModal] = useState(false)
  const [editSearchItem, setEditSearchItem] = useState(null)
  const [alerts, setAlerts] = useState(null)

  const queryParams = new URLSearchParams(
    typeof window !== 'undefined' && window.location.search
  )
  let alertEmail = queryParams.get('email')

  const { Option } = Select

  useEffect(() => {
    axios
      .get(strapiURL + `/job-alerts-email/${decodeURI(alertEmail)}`)
      .then(res => {
        setAlerts(res?.data || [])
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }, [])
  const runSearch = val => {
    const _location =
      val.location !== null && Object?.keys(val?.location)?.length > 0
        ? encodeURIComponent(JSON.stringify(val?.location))
        : null

    let _vals = {
      title: val?.keyword || '',
      travel_percentage: val?.travel_percentage || '',
      compensation_type:
        val.compensation_type === 'Not Specified' ? '' : val.compensation_type,
      primary_function:
        val.primary_function === 'Not Specified'
          ? ''
          : val.primary_function?.toString(),
      product_category: val?.industry || '',
      remote_enabled: val?.remote_enabled || false,
      field_based: val?.field_based || false,
      location: _location,

      max_compensation: parseInt(val.max_compensation || 500),
      min_compensation: parseInt(val.min_compensation || 0),
      distance: val.distance,
    }

    let URL = serialize(_vals)
    navigate(`/job-search/?` + URL)
  }

  const deleteSearchHandler = deleteId => {
    axios
      .delete(strapiURL + `/delete-job-alerts-email/${deleteId}`)
      .then(res => {
        message.success('Successfully deleted.')
        let _copy = [...alerts]
        _copy = _copy.filter(item => item?.id !== deleteId)
        setAlerts(_copy)
      })
      .catch(err => {
        message.error('Something went wrong.')
      })
  }

  const changeFrequencyHandler = (val, editItem) => {
    axios
      .put(strapiURL + `/update-job-alerts-email/${editItem?.id}`, {
        frequency: val,
      })
      .then(res => {
        if (alerts?.length) {
          let _copyAll = [...alerts]
          _copyAll[_copyAll.findIndex(item => item?.id === editItem?.id)] =
            res.data
          setAlerts(_copyAll)
        }

        message.success('Search updated.')
      })
      .catch(err => {
        message.error('Something went wrong')
      })
  }

  const alertCols = [
    {
      title: 'Search Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => {
        return (
          <p className="mb-0 font-sans font-bold">
            {record?.name || 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      render: (text, record, index) => {
        return (
          <div className="flex items-center ">
            <img src="/icons/icon-location-pin.svg" className="mb-0" />
            {record?.location?.label ? (
              <span className="text-last text-location leading-0 ml-1">
                {record?.location?.label}
              </span>
            ) : (
              <span className="text-last text-location leading-0 ml-1">
                Not Specified
              </span>
            )}
          </div>
        )
      },
    },
    {
      title: 'Function',
      dataIndex: 'primary_function',
      key: 'primary_function',
      render: (text, record, index) => {
        return (
          <p className="text-location text-last font-sans mb-0">
            {record.primary_function
              ? displayValueHandler(record?.primary_function)
              : 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Compensation Range',
      dataIndex: 'compensation',
      key: 'compensation',
      render: (text, record, index) => {
        return (
          <>
            {!record.max_compensation || !record.min_compensation ? (
              <p className="text-location text-last font-sans mb-0">
                Not Specified
              </p>
            ) : (
              <p className="text-location text-last font-sans mb-0">
                ${record.min_compensation}K - ${record.max_compensation}K
              </p>
            )}
          </>
        )
      },
    },

    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      render: (text, record, index) => {
        return (
          <Select
            onChange={val => {
              changeFrequencyHandler(val, record)
            }}
            className="font-sans text-last text-jobTag w-[100px]"
            value={record.frequency}
          >
            {emailFrequencyJobAlert.map((freq, index) => {
              return (
                <Option
                  className="font-sans text-last text-jobTag"
                  value={freq}
                  key={index}
                >
                  {freq}
                </Option>
              )
            })}
          </Select>
        )
      },
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (text, record, index) => {
        return (
          <div className="flex items-center">
            <span
              onClick={() => {
                setEditSearchItem(record)
                setEditSearchModal(true)
              }}
              className="cursor-pointer font-sans text-last text-merlot mb-0"
            >
              Edit
            </span>
            <div className="h-4 w-px bg-merlot mx-2" />
            <span
              onClick={() => runSearch(record)}
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
            >
              Run
            </span>
            <div className="h-4 w-px bg-merlot mx-2" />
            <span
              onClick={() => deleteSearchHandler(record.id)}
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
            >
              Delete
            </span>
          </div>
        )
      },
    },
  ]

  return (
    <div className="container mx-auto px-6 sm:px-0">
      {loading ? (
        <div
          className="w-full bg-no-repeat bg-cover py-44"
          style={{
            backgroundImage: `url(/loaders/table-loader.png)`,
          }}
        />
      ) : (
        <>
          <div className="mt-20">
            <div className="flex px-4 py-4 border-b border-bgSecondaryButton ">
              <img src="/icons/icon-alert-1.svg" className="mb-0" />
              <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
                Your Alerts
              </h1>
            </div>
            <Table
              className="hide-table-header-background hide-table-header-cols-border hidden sm:block"
              columns={alertCols}
              dataSource={alerts || []}
              pagination={false}
              bordered={false}
            />
            <div className="alerts-dashboard mb-6 visible sm:hidden">
              {alerts &&
                alerts.map(alert => {
                  return (
                    <div
                      key={alert.id}
                      className="list flex justify-between py-6"
                    >
                      <h3>{alert.name}</h3>
                      <Dropdown
                        trigger={['click']}
                        overlay={
                          <Menu>
                            <Menu.Item>
                              <a
                                className="font-sans text-sm"
                                onClick={() => {
                                  setEditSearchItem(alert)
                                  setEditSearchModal(true)
                                }}
                              >
                                Edit
                              </a>
                            </Menu.Item>
                            <Menu.Item>
                              <a
                                className="font-sans text-sm"
                                onClick={() => runSearch(alert)}
                              >
                                Run
                              </a>
                            </Menu.Item>
                            <Menu.Item>
                              <a
                                className="font-sans text-sm"
                                onClick={() => deleteSearchHandler(alert.id)}
                              >
                                Delete
                              </a>
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <a onClick={e => e.preventDefault()}>
                          <img
                            src="/icons/options.png"
                            className="self-center cursor-pointer"
                          />
                        </a>
                      </Dropdown>
                    </div>
                  )
                })}
            </div>
          </div>
        </>
      )}
      <EditSearchModal
        modalOpen={editSearchModal}
        setModalOpen={setEditSearchModal}
        editItem={editSearchItem}
        setEditItem={setEditSearchItem}
        userProfile={userProfile}
        currentData={alerts}
        setCurrentData={setAlerts}
      />
    </div>
  )
}
export default Alerts
